import { styled, Typography } from '@mui/material';

export const StyledLogo = styled('img')(() => ({
  height: '4em',
  width: '4em'
}));

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '1.3em',
  fontWeight: 'bold',
  paddingLeft: '4px',
  paddingBottom: '4px',
  flexGrow: 1
}));

export const BottomNavContainer = styled('div')(() => ({
  backgroundColor: '#F5F5F7',
  minHeight: '300px'
}));

export const BottomNav = styled('div')(() => ({
  maxWidth: '1200px',
  justifyContent: 'center',
  margin: '1em auto',
  textAlign: 'left'
}));

export const SocialMediaIcon = styled('img')(({ theme }) => ({
  height: '10vw',
  maxHeight: theme.spacing(4),
  margin: theme.spacing(1),
  opacity: 0.3
}));

export const SocialMediaContainer = styled('div')(() => ({
  display: 'flex',
  margin: '20px 1em',
  alignContent: 'center',
  alignItems: 'center'
}));
