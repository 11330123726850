export const backgroundColor = 'rgba(33, 34, 38, 1)';
export const primaryColor = 'rgba(124, 46, 255, 1)';

export const colors = {
  grey: {
    1: '#FFFFFF',
    2: '#CCCCCC',
    3: '#707070',
    4: '#545454',
    5: '#404040',
    6: '#262626',
    7: '#1A1A1A'
  }
};
