import { styled } from '@mui/material';
import { colors } from 'src/styles/color';

export const FooterContainer = styled('div')({
  maxWidth: '1200px'
});

export const GreyText = styled('span')({
  fontSize: 14,
  color: colors.grey[3]
});
