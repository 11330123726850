import { styled, Typography, Grid } from '@mui/material';

import { primaryColor } from 'src/styles/color';

export const Title = styled(Typography)({
  fontSize: '2.4em',
  margin: '0 auto',
  fontWeight: 'bold',
  '@media (max-width: 640px)': {
    fontSize: '1.8em'
  }
});

export const PurpleText = styled('span')({
  color: primaryColor
});

export const Subtitle = styled(Typography)({
  fontSize: 20,
  margin: '20px auto'
});

export const Description = styled(Typography)({
  fontSize: 14,
  color: 'grey',
  margin: '12px auto'
});

export const MainContainer = styled('div')(() => ({
  maxWidth: '1100px',
  margin: '0 auto',
  paddingBottom: '120px'
}));

export const GridContainer = styled(Grid)({
  width: '100%',
  margin: '1em auto'
});

export const DownloadLogo = styled('img')(() => ({
  height: '52px',
  padding: 'auto 8px'
}));

export const DemoImg = styled('img')(() => ({
  width: '70%',
  height: 'auto',
  minWidth: '320px'
}));

export const DemoVideo = styled('iframe')({
  width: '90%',
  height: '600px',
  '@media (max-width: 640px)': {
    height: '250px'
  }
});

export const FeatureImg = styled('img')(() => ({
  width: '50%',
  height: 'auto',
  minWidth: '200px',
  borderRadius: '10px'
}));

export const MemberItem = styled('div')({
  backgroundColor: '#303135',
  width: '260px',
  margin: '0 auto',
  borderRadius: '10px',
  padding: '1em 0'
});

export const DownloadImg = styled('img')({
  height: '52px',
  margin: '2em auto'
});
