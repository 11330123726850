// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC4vS7LIoiUK6UcSbn77x8_bfghs8uwHe4',
  authDomain: 'chataverse-123.firebaseapp.com',
  projectId: 'chataverse-123',
  storageBucket: 'chataverse-123.appspot.com',
  messagingSenderId: '769426496735',
  appId: '1:769426496735:web:49ef3b2cc3b49853b95d6a',
  measurementId: 'G-PZW8BL2P24'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
