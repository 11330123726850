import { styled, Grid } from '@mui/material';

import { colors } from 'src/styles/color';

export const Container = styled('div')({
  margin: '2em auto'
});

export const FeatureGrid = styled(Grid)({
  justifyContent: 'center',
  alignItems: 'center',
  margin: '4em auto'
});

export const DescriptionContainer = styled('div')({
  textAlign: 'left'
});

export const FeatureImg = styled('img')({
  width: '500px',
  '@media (max-width: 640px)': {
    width: '90%'
  }
});

export const Title = styled('h1')({
  fontWeight: 'bold'
});

export const DetailList = styled('ul')({
  color: colors.grey[2]
});

export const PurpleShadow1 = styled('div')({
  position: 'relative',
  width: '100px',
  height: '100px',
  background: 'rgba(104, 80, 252, 0.7)',
  filter: 'blur(50px)',
  top: 100,
  left: -60,
  zIndex: 1
});

export const PurpleShadow2 = styled('div')({
  position: 'relative',
  width: '100px',
  height: '100px',
  background: 'rgba(104, 80, 252, 0.7)',
  filter: 'blur(70px)',
  top: 250,
  left: 400,
  zIndex: 1,
  '@media (max-width: 640px)': {
    width: '50px',
    height: '50px',
    top: 250,
    left: 200
  }
});

export const GreenShadow = styled('div')({
  position: 'relative',
  width: '100px',
  height: '100px',
  left: 10,
  top: 300,
  background: 'rgba(6, 166, 138, 0.7)',
  filter: 'blur(80px)',
  '@media (max-width: 640px)': {
    width: '70px',
    height: '70px',
    top: 250,
    left: 40
  }
});

export const OrangeShadow = styled('div')({
  position: 'relative',
  width: '150px',
  height: '150px',
  left: -100,
  top: 300,
  background: 'rgba(224, 90, 14, 0.7)',
  filter: 'blur(80px)',
  '@media (max-width: 640px)': {
    width: '70px',
    height: '70px',
    top: 0,
    left: 200
  }
});
