import { FC } from 'react';
import { AppBar, Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import { backgroundColor } from 'src/styles/color';
import { StyledLogo, SocialMediaContainer, StyledTitle, SocialMediaIcon } from './styles';
import Logo from 'src/assets/logo/logo.png';
import { SocialMedia } from 'src/content';

const TopNavBar: FC = () => {
  const socialMediaUI = SocialMedia.socialMedia.map((socialMedia) => (
    <a key={socialMedia.name} target="_blank" rel="noopener noreferrer" href={socialMedia.url}>
      <SocialMediaIcon src={socialMedia.logo} alt={socialMedia.name} />
    </a>
  ));

  return (
    <AppBar position="static" color="inherit" style={{ backgroundColor: backgroundColor }}>
      <Container
        sx={{
          flexGrow: 1
        }}>
        <Grid
          container
          direction="row"
          alignItems="space-between"
          justifyContent="space-between"
          sx={{
            display: 'flex',
            width: '100%'
          }}>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link to="/">
                <StyledLogo src={Logo} alt="Chataverse Logo" />
              </Link>
              <StyledTitle sx={{ flexGrow: 1 }}>Chataverse</StyledTitle>
            </div>
          </Grid>
          <Grid item xs="auto">
            <SocialMediaContainer>{socialMediaUI}</SocialMediaContainer>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default TopNavBar;
