import { Grid } from '@mui/material';

import { TopNavBar } from 'src/components';
import DemoPNG from 'src/assets/img/welcome-demo.png';
import TestflightDownload from 'src/assets/img/testflight-download.png';

import Footer from '../Footer';

import Feature from './Feature';
import { MainContainer, GridContainer, Title, PurpleText, DemoImg, DownloadImg } from './styles';

const TESTFLIGHT_URL = 'https://testflight.apple.com/join/5ajf3RgR';

const HomePage = () => {
  return (
    <div>
      <TopNavBar />
      <MainContainer>
        <GridContainer
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={2}>
          <Grid item xs={12}>
            <Title>
              Meet <PurpleText>like-minded</PurpleText> friends,
            </Title>
            <Title>
              from your <PurpleText>NFTs</PurpleText>
            </Title>
            <a href={TESTFLIGHT_URL} target="_blank" rel="noopener noreferrer">
              <DownloadImg src={TestflightDownload} alt="" />
            </a>
          </Grid>

          <Grid item xs={12}>
            <DemoImg src={DemoPNG} alt="demo" />
          </Grid>

          <Grid item xs={12}>
            <Feature />
          </Grid>

          <Grid item xs={12} my={14}>
            <h2>More crypto-native features coming soon</h2>
            <a href={TESTFLIGHT_URL} target="_blank" rel="noopener noreferrer">
              <DownloadImg src={TestflightDownload} alt="" />
            </a>
          </Grid>
        </GridContainer>

        <Footer />
      </MainContainer>
    </div>
  );
};

export default HomePage;
