import TwitterLogo from 'src/assets/logo/twitter.png';
import InstagramLogo from 'src/assets/logo/instagram.png';

const SocialMedia = {
  socialMedia: [
    { name: 'Twitter', logo: TwitterLogo, url: 'https://twitter.com/chataverse' },
    { name: 'Instagram', logo: InstagramLogo, url: 'https://www.instagram.com/chataverse' }
  ]
};

export default SocialMedia;
