import React from 'react';
import { Grid } from '@mui/material';

import ChatIcon from 'src/assets/img/chat-icon.png';
import ToolIcon from 'src/assets/img/tool-icon.png';
import ChataccountIcon from 'src/assets/img/chataccount-icon.png';
import NFTGatedFeature from 'src/assets/img/feature-nft-gated.png';
import ManagementToolFeature from 'src/assets/img/feature-management-tool.png';
import ChataccountFeature from 'src/assets/img/feature-chataccount.png';

import {
  FeatureImg,
  Container,
  FeatureGrid,
  Title,
  DescriptionContainer,
  DetailList,
  PurpleShadow1,
  PurpleShadow2,
  GreenShadow,
  OrangeShadow
} from './styles';

const Feature = () => {
  return (
    <Container>
      <FeatureGrid container px={1} direction={{ xs: 'row-reverse' }}>
        <Grid item xs={12} md={6}>
          <DescriptionContainer>
            <img src={ChatIcon} alt="" width="50px" />
            <Title>Token-gated group chats</Title>
            <h4>Securely chat & interact with other holders in your NFT community.</h4>
            <DetailList>
              <li>
                <span>Chat with fellow holders without spam and scams</span>
              </li>
              <li>
                <span>Create and join groups within your NFT collections</span>
              </li>
              <li>
                <span>Privately chat with other addresses with one click</span>
              </li>
            </DetailList>
          </DescriptionContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <PurpleShadow1 />
          <PurpleShadow2 />

          <FeatureImg src={NFTGatedFeature} alt="" style={{ zIndex: 1 }} />
        </Grid>
      </FeatureGrid>

      <FeatureGrid container px={1}>
        <Grid item xs={12} md={6}>
          <DescriptionContainer>
            <img src={ToolIcon} alt="" width="50px" />
            <Title>Built-in community management tools</Title>
            <h4>No more bots and plug-ins - everything integrated.</h4>

            <DetailList>
              <li>
                <span>Vote for new proposals within your NFT community</span>
              </li>
              <li>
                <span>Create and attend events hosted by your community</span>
              </li>
              <li>
                <span>Airdrop rewards with one click - no more “DM the addresse”</span>
              </li>
            </DetailList>
          </DescriptionContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <GreenShadow />
          <FeatureImg src={ManagementToolFeature} alt="" />
        </Grid>
      </FeatureGrid>

      <FeatureGrid container px={1} direction={{ md: 'row-reverse' }}>
        <Grid item xs={12} md={6}>
          <DescriptionContainer>
            <img src={ChataccountIcon} alt="" width="50px" />
            <Title>Chataccount</Title>
            <h4>The only web3 identity you need to chat, interact, and transact.</h4>
            <DetailList>
              <li>
                <span>Aggregate your wallets from multiple chains into one identity</span>
              </li>
              <li>
                <span>Build up your unique NFT profile page to display</span>
              </li>
              <li>
                <span>Send and recieve tokens using your “.chata” account name</span>
              </li>
            </DetailList>
          </DescriptionContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <OrangeShadow />
          <FeatureImg src={ChataccountFeature} alt="" />
        </Grid>
      </FeatureGrid>
    </Container>
  );
};

export default Feature;
