import { FC } from 'react';
import { Grid } from '@mui/material';

import AppleLogo from 'src/assets/img/apple-logo.png';
import TeslaLogo from 'src/assets/img/tesla-logo.png';
import BytedanceLogo from 'src/assets/img/bytedance-logo.png';
import JPMorganLogo from 'src/assets/img/jp-morgan-logo.png';
import YCLogo from 'src/assets/img/y-combinator.png';

import { FooterContainer } from './styles';

const Footer: FC = () => {
  return (
    <FooterContainer>
      <div>
        <span>With support from</span>
      </div>

      <Grid container justifyContent="center" alignItems="center" my={4}>
        <Grid item>
          <a href="https://www.ycombinator.com/" target="_blank" rel="noreferrer">
            <img src={YCLogo} alt="" width="180px" />
          </a>
        </Grid>
      </Grid>

      <div>
        <span>Made with ❤️ by builders from</span>
      </div>

      <Grid container justifyContent="center" alignItems="center" spacing={3} my={2}>
        <Grid item>
          <img src={AppleLogo} alt="logo" width="40px" />
        </Grid>
        <Grid item>
          <img src={TeslaLogo} alt="logo" width="40px" />
        </Grid>
        <Grid item>
          <img src={BytedanceLogo} alt="logo" width="140px" />
        </Grid>
        <Grid item>
          <img src={JPMorganLogo} alt="logo" width="140px" />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" alignItems="center" my={4}>
        <Grid item>
          <span>© 2022. DeLiquid Lab</span>
        </Grid>
        <Grid item>
          <span>
            &nbsp; · &nbsp;
            <a href="https://docs.chataverse.io/" target="_blank" rel="noopener noreferrer">
              Docs
            </a>
          </span>
        </Grid>
        <Grid item>
          <span>
            &nbsp; · &nbsp;
            <a
              href="https://www.termsfeed.com/live/4203d2ff-ef18-4455-b2c5-39fd58fdaa95"
              target="_blank"
              rel="noopener noreferrer">
              Privacy policy
            </a>
          </span>
        </Grid>
        <Grid item>
          <span>
            &nbsp; · &nbsp;
            <a
              href="https://www.termsfeed.com/live/0801cba3-def5-4aca-b65f-121ed7c9f175"
              target="_blank"
              rel="noopener noreferrer">
              Terms of service
            </a>
          </span>
        </Grid>
      </Grid>
    </FooterContainer>
  );
};

export default Footer;
